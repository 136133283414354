import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/yuncut_q/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    name: 'claim_print',
    path: '/ClaimList/components/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__supplier__ClaimList__components__print" */ '../supplier/ClaimList/components/print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/ClaimList/components/print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'login',
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__Login__model.ts' */ 'D:/yuncut_q/src/pages/supplier/Login/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__Login" */ '../supplier/Login'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/Login').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'labelPrint',
    path: '/label/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__deliver__Print" */ '../supplier/deliver/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/deliver/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'deliverPrint',
    path: '/deliver/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__deliver__Print" */ '../supplier/deliver/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/deliver/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'orderPrint',
    path: '/order/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodOrder/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__prodOrder__Detail__Print" */ '../supplier/prodOrder/Detail/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/prodOrder/Detail/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'prodPlanPrint',
    path: '/prod-plan/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodPlan/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__prodPlan__Print" */ '../supplier/prodPlan/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/prodPlan/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'prodOrderPrint',
    path: '/prod-order/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodOrder/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__prodOrder__Print" */ '../supplier/prodOrder/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/prodOrder/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'print',
    path: '/print/steel-plates',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__supplier__materialLibrary__List__Prints__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/List/Prints/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
            import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__supplier__materialLibrary__List__Prints" */ '../supplier/materialLibrary/List/Prints'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../supplier/materialLibrary/List/Prints').default,
    hideInMenu: true,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'layouts__supplier__model.ts' */ 'D:/yuncut_q/src/layouts/supplier/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../../layouts/supplier/SupplierLayout'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/supplier/SupplierLayout').default,
    authority: ['ROLE_SUPPLIER'],
    routes: [
      {
        name: 'home',
        icon: 'home',
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__supplier__Overview__models__statisticsFilter.ts' */ 'D:/yuncut_q/src/pages/supplier/Overview/models/statisticsFilter.ts').then(
                  m => {
                    return { namespace: 'statisticsFilter', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__supplier__Overview__models__supplierStatisticsView.ts' */ 'D:/yuncut_q/src/pages/supplier/Overview/models/supplierStatisticsView.ts').then(
                  m => {
                    return {
                      namespace: 'supplierStatisticsView',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__supplier__Overview" */ '../supplier/Overview'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/Overview').default,
        exact: true,
      },
      {
        name: 'prod-plan',
        path: '/prod-plan',
        icon: 'FileSearchOutlined',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/prod-plan',
            redirect: '/prod-plan/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/prod-plan/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodPlan/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__prodPlan__List" */ '../supplier/prodPlan/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/prodPlan/List').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/prod-plan/detail',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodPlan/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__prodPlan__Detail" */ '../supplier/prodPlan/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/prodPlan/Detail').default,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'qualityControl',
        path: '/qualityControl',
        icon: 'project',
        routes: [
          {
            path: '/qualityControl',
            redirect: '/qualityControl/List',
            exact: true,
          },
          {
            icon: 'project',
            name: 'dataLibrary',
            path: '/qualityControl/List',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__List" */ '../supplier/qualityControl/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/List').default,
            exact: true,
          },
          {
            name: 'dataLibraryDetail',
            path: '/qualityControl/List/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__List__detail" */ '../supplier/qualityControl/List/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/List/detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'share-alt',
            name: 'experienceSharing',
            path: '/qualityControl/experienceSharing',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__experienceSharing" */ '../supplier/qualityControl/experienceSharing'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/experienceSharing').default,
            exact: true,
          },
          {
            name: 'experienceSharingDetail',
            path: '/qualityControl/experienceSharing/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__experienceSharing__detail" */ '../supplier/qualityControl/experienceSharing/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/experienceSharing/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'play-square',
            name: 'videos',
            path: '/qualityControl/videos',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__videos" */ '../supplier/qualityControl/videos'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/videos').default,
            exact: true,
          },
          {
            icon: 'calendar',
            name: 'train',
            path: '/qualityControl/train',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__train" */ '../supplier/qualityControl/train'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/train').default,
            exact: true,
          },
          {
            name: 'train-detail',
            path: '/qualityControl/train/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__train__detail__index" */ '../supplier/qualityControl/train/detail/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/train/detail/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'spotCheck',
            path: '/qualityControl/spotCheck',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/supplier/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__qualityControl__spotCheck" */ '../supplier/qualityControl/spotCheck'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/qualityControl/spotCheck').default,
            exact: true,
          },
        ],
      },
      {
        name: 'program',
        path: '/program',
        icon: 'laptop',
        routes: [
          {
            path: '/program',
            redirect: '/program/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/program/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__program__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__program__List" */ '../supplier/program/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/program/List').default,
            exact: true,
          },
          {
            name: 'summary',
            path: '/program/summary',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__program__Summary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/Summary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__program__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__program__Summary" */ '../supplier/program/Summary'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/program/Summary').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/program/detail',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__program__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__program__Detail" */ '../supplier/program/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/program/Detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'report',
            path: '/program/report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__program__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__program__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__program__Form" */ '../supplier/program/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/program/Form').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'cuttingStatistics',
            path: '/program/cuttingStatistics',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__program__model.ts' */ 'D:/yuncut_q/src/pages/supplier/program/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__program__cuttingStatistics" */ '../supplier/program/cuttingStatistics'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/program/cuttingStatistics').default,
            exact: true,
          },
        ],
      },
      {
        name: 'process',
        path: '/process',
        icon: 'laptop',
        routes: [
          {
            path: '/process',
            redirect: '/process/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/process/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__process__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/process/List/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__process__List" */ '../supplier/process/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/process/List').default,
            exact: true,
          },
          {
            name: 'report',
            path: '/process/report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__process__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/process/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__process__Form" */ '../supplier/process/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/process/Form').default,
            hideInMenu: true,
            exact: true,
          },
        ],
      },
      {
        name: 'prod-order',
        path: '/prod-order',
        icon: 'cluster',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/prod-order',
            redirect: '/prod-order/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/prod-order/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__prodOrder__List" */ '../supplier/prodOrder/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/prodOrder/List').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/prod-order/detail',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/prodOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__prodOrder__Detail" */ '../supplier/prodOrder/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/prodOrder/Detail').default,
            exact: true,
          },
        ],
      },
      {
        name: 'deliver',
        path: '/deliver',
        icon: 'shopping-cart',
        routes: [
          {
            path: '/deliver',
            redirect: '/deliver/list',
            exact: true,
          },
          {
            name: 'can-deliver-list',
            path: '/deliver/can-deliver-list',
            icon: 'shopping-cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__deliver__CanDeliverList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/CanDeliverList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__deliver__CanDeliverList" */ '../supplier/deliver/CanDeliverList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/deliver/CanDeliverList').default,
            exact: true,
          },
          {
            name: 'list',
            path: '/deliver/list',
            icon: 'shopping-cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__deliver" */ '../supplier/deliver'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/deliver').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/deliver/detail',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__deliver__detail" */ '../supplier/deliver/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/deliver/detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'form',
            path: '/deliver/form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__deliver__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__deliver__Form" */ '../supplier/deliver/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/deliver/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'order-track',
        icon: 'select',
        path: '/order-track',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/order-track',
            redirect: '/order-track/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/order-track/list',
            icon: 'select',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__orderTrack__model.ts' */ 'D:/yuncut_q/src/pages/supplier/orderTrack/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__orderTrack__list" */ '../supplier/orderTrack/list'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/orderTrack/list').default,
            exact: true,
          },
        ],
      },
      {
        name: 'supplement',
        path: '/supplement',
        icon: 'car',
        routes: [
          {
            path: '/supplement',
            redirect: '/supplement/CanSupplementList/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/supplement/CanSupplementList/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__supplement__CanSupplementList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/CanSupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__supplement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__supplement__CanSupplementList" */ '../supplier/supplement/CanSupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/supplement/CanSupplementList').default,
            exact: true,
          },
          {
            name: 'CanSupplement-patchDetails',
            path: '/supplement/CanSupplementList/PatchDetails',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__supplement__CanSupplementList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/CanSupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__supplement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__supplement__CanSupplementList__PatchDetails" */ '../supplier/supplement/CanSupplementList/PatchDetails'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/supplement/CanSupplementList/PatchDetails')
                  .default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'ApplySupplementList',
            path: '/supplement/ApplySupplementList/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__supplement__ApplySupplementList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/ApplySupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__supplement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__supplement__ApplySupplementList" */ '../supplier/supplement/ApplySupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/supplement/ApplySupplementList').default,
            exact: true,
          },
          {
            name: 'SupplementedList',
            path: '/supplement/SupplementedList/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__supplement__SupplementedList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/SupplementedList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__supplement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__supplement__SupplementedList" */ '../supplier/supplement/SupplementedList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/supplement/SupplementedList').default,
            exact: true,
          },
          {
            name: 'RejectSupplementList',
            path: '/supplement/RejectSupplementList/list',
            icon: 'FileSearchOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__supplement__RejectSupplementList__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/RejectSupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__supplement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/supplement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__supplement__RejectSupplementList" */ '../supplier/supplement/RejectSupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/supplement/RejectSupplementList').default,
            exact: true,
          },
        ],
      },
      {
        name: 'postSale',
        icon: 'BlockOutlined',
        path: '/postSale',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier__postSale" */ '../supplier/postSale'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/postSale').default,
        exact: true,
      },
      {
        name: 'postSaleDetail',
        path: '/postSale/detail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier__postSale__Detail" */ '../supplier/postSale/Detail'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/postSale/Detail').default,
        showBack: true,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'ClaimList',
        icon: 'FileProtectOutlined',
        path: '/ClaimList',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier__ClaimList" */ '../supplier/ClaimList'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/ClaimList').default,
        exact: true,
      },
      {
        name: 'postSale_ClaimDetail',
        path: '/postSale/postSale_ClaimDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier__postSale__components__ClaimDetail" */ '../supplier/postSale/components/ClaimDetail'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/postSale/components/ClaimDetail').default,
        showBack: true,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'badReportList',
        icon: 'BugOutlined',
        path: '/badReportList',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier__postSale__adverseReportList" */ '../supplier/postSale/adverseReportList'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/postSale/adverseReportList').default,
        exact: true,
      },
      {
        name: 'machineSchedule',
        icon: 'apartment',
        path: '/machineSchedule',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__supplier__machineSchedule__model.ts' */ 'D:/yuncut_q/src/pages/supplier/machineSchedule/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__supplier__machineSchedule" */ '../supplier/machineSchedule'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/machineSchedule').default,
        exact: true,
      },
      {
        name: 'product-library',
        path: '/product-library',
        icon: 'apartment',
        routes: [
          {
            name: 'productLibrary',
            path: '/product-library/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__List" */ '../supplier/productLibrary/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/List').default,
            exact: true,
          },
          {
            name: 'productStockInOutReport',
            path: '/product-library/productStockInOutReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__productStockInOutReport__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/productStockInOutReport/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__productStockInOutReport" */ '../supplier/productLibrary/productStockInOutReport'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/productStockInOutReport')
                  .default,
            exact: true,
          },
          {
            name: 'productStockInSupplier',
            path: '/product-library/productStockInSupplier',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__productStockInSupplier" */ '../supplier/productLibrary/productStockInSupplier'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/productStockInSupplier')
                  .default,
            exact: true,
          },
          {
            name: 'productStockOutSupplier',
            path: '/product-library/productStockOutSupplier',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__productStockOutSupplier" */ '../supplier/productLibrary/productStockOutSupplier'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/productStockOutSupplier')
                  .default,
            exact: true,
          },
          {
            name: 'productStorehouse-in-detail',
            icon: 'car',
            path: '/product-library/productStorehouse-in-detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__productStockInSupplier__detail" */ '../supplier/productLibrary/productStockInSupplier/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/productStockInSupplier/detail')
                  .default,
            showBack: true,
            exact: true,
          },
          {
            name: 'productStorehouse-out-detail',
            icon: 'car',
            path: '/product-library/productStorehouse-out-detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__productLibrary__productStockOutSupplier__detail" */ '../supplier/productLibrary/productStockOutSupplier/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/productLibrary/productStockOutSupplier/detail')
                  .default,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'material-library',
        path: '/material-library',
        icon: 'cluster',
        routes: [
          {
            name: 'material',
            path: '/material-library/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__MaterialLibrary" */ '../supplier/materialLibrary/MaterialLibrary'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/MaterialLibrary').default,
            exact: true,
          },
          {
            name: 'surplus-material',
            path: '/material-library/surplus-material',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__SurplusMaterialLibrary" */ '../supplier/materialLibrary/SurplusMaterialLibrary'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/SurplusMaterialLibrary')
                  .default,
            exact: true,
          },
          {
            name: 'steelScrap-material',
            path: '/material-library/steelScrap-material',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__steelScrapMaterial__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/steelScrapMaterial/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__steelScrapMaterial__List" */ '../supplier/materialLibrary/steelScrapMaterial/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/steelScrapMaterial/List')
                  .default,
            exact: true,
          },
          {
            name: 'materialPurchase',
            path: '/material-library/materialPurchase',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__materialPurchase__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/materialPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__materialPurchase" */ '../supplier/materialLibrary/materialPurchase'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/materialPurchase').default,
            exact: true,
          },
          {
            name: 'surplusPurchase',
            path: '/material-library/surplusPurchase',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__surplusPurchase__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/surplusPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__surplusPurchase" */ '../supplier/materialLibrary/surplusPurchase'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/surplusPurchase').default,
            exact: true,
          },
          {
            name: 'materialScience',
            path: '/material-library/materialScience',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/materialScience/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__materialScience" */ '../supplier/materialLibrary/materialScience'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/materialScience').default,
            exact: true,
          },
          {
            name: 'materialStockInOutReport',
            path: '/material-library/materialStockInOutReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__materialStockInOutReport__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/materialStockInOutReport/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__supplier__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/supplier/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__materialLibrary__materialStockInOutReport" */ '../supplier/materialLibrary/materialStockInOutReport'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/materialLibrary/materialStockInOutReport')
                  .default,
            exact: true,
          },
        ],
      },
      {
        name: 'settlement',
        path: '/settlement',
        icon: 'MoneyCollect',
        routes: [
          {
            path: '/settlement',
            redirect: '/settlement/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/settlement/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement" */ '../supplier/settlement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement').default,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDetail',
            name: 'settlement_mtSaleDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlement_mtSale__MtSaleDetailed" */ '../supplier/settlement/settlement_mtSale/MtSaleDetailed'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlement_mtSale/MtSaleDetailed')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliverDetail',
            name: 'settlement_mtSaleDeliverDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlement_mtSale__MtSaleDetailed__MtOrderDeliverDetailed" */ '../supplier/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementPaymentPlan',
            name: 'settlementPaymentPlan',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__paymentPlan" */ '../supplier/settlement/paymentPlan'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/paymentPlan').default,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter',
            name: 'settlementCenter',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlementCenter" */ '../supplier/settlement/settlementCenter'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlementCenter').default,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter/settlementDetail',
            name: 'settlementDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlementCenter__settlementDetail" */ '../supplier/settlement/settlementCenter/settlementDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlementCenter/settlementDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter/paymentApplyDetail',
            name: 'paymentApplyDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlementCenter__paymentApplyDetail" */ '../supplier/settlement/settlementCenter/paymentApplyDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlementCenter/paymentApplyDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementInvoice',
            name: 'settlementInvoice',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__invoice" */ '../supplier/settlement/invoice'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/invoice').default,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale',
            name: 'settlement_mtSale',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__settlement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__settlement__settlement_mtSale" */ '../supplier/settlement/settlement_mtSale'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/settlement/settlement_mtSale').default,
            exact: true,
          },
        ],
      },
      {
        name: 'invoice',
        icon: 'DollarOutlined',
        path: '/invoice',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__supplier__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/InvoiceInformation/List/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__supplier__InvoiceInformation__List" */ '../supplier/InvoiceInformation/List'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../supplier/InvoiceInformation/List').default,
        exact: true,
      },
      {
        name: 'my-salesOrder',
        path: '/my-salesOrder',
        icon: 'cluster',
        routes: [
          {
            name: 'my-factory',
            path: '/my-salesOrder/my-factory',
            icon: 'cluster',
            routes: [
              {
                name: 'businessNavigator',
                path: '/my-salesOrder/my-factory/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/myFactory/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'order',
                path: '/my-salesOrder/my-factory/order',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/order',
                    redirect: '/my-salesOrder/my-factory/order/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/my-factory/order/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/list'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/list')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/my-factory/order/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'form',
                    path: '/my-salesOrder/my-factory/order/form',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/Form'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/Form')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialForm',
                    path: '/my-salesOrder/my-factory/order/materialForm',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__MaterialForm__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/MaterialForm/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/MaterialForm'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/MaterialForm')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialSave',
                    path: '/my-salesOrder/my-factory/order/list/materialSave',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/list/materialSave'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/list/materialSave')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'PartsDetail',
                    path: '/my-salesOrder/my-factory/order/PartsDetail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__PartsDetail__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/PartsDetail/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/PartsDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/PartsDetail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'MaterialDetail',
                    path: '/my-salesOrder/my-factory/order/MaterialDetail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__MaterialDetail__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/MaterialDetail/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__order__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/order/MaterialDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/order/MaterialDetail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'orderReport',
                path: '/my-salesOrder/my-factory/orderReport',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__orderReport__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/orderReport/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/orderReport/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/myFactory/orderReport/list')
                      .default,
                exact: true,
              },
              {
                name: 'deliver',
                path: '/my-salesOrder/my-factory/deliver',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/deliver',
                    redirect: '/my-salesOrder/my-factory/deliver/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/my-factory/deliver/list',
                    icon: 'shopping-cart',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path: '/my-salesOrder/my-factory/deliver/detail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/my-factory/deliver/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'MaterialSave',
                    path: '/my-salesOrder/my-factory/deliver/MaterialSave',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/MaterialSave'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/MaterialSave')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialDetail',
                    path: '/my-salesOrder/my-factory/deliver/materialDetail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/MaterialDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/MaterialDetail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partsEdit',
                    path: '/my-salesOrder/my-factory/deliver/partsEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/deliveryPartsEdit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/deliveryPartsEdit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialEdit',
                    path: '/my-salesOrder/my-factory/deliver/materialEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__deliver__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/deliver/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/deliver/deliveryMaterialEdit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/deliver/deliveryMaterialEdit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'collection',
                path: '/my-salesOrder/my-factory/collection',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__collection__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/collection/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/collection/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/myFactory/collection/list')
                      .default,
                exact: true,
              },
              {
                name: 'sales-freight',
                path: '/my-salesOrder/my-factory/sales-freight',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesFreight__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesFreight/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesFreight/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/myFactory/salesFreight/list')
                      .default,
                exact: true,
              },
              {
                name: 'sales-invoice',
                path: '/my-salesOrder/my-factory/sales-invoice',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/my-factory/sales-invoice',
                    redirect: '/my-salesOrder/my-factory/sales-invoice/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'material-add',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/my-factory/sales-invoice/material-add',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'part-add',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-add',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'part-edit',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'material-edit',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/my-factory/sales-invoice/material-edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'part-detail',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/my-factory/sales-invoice/part-detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/Detail/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/PartSalesInvoice/Detail/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'material-detail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/my-factory/sales-invoice/material-detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__myFactory__salesInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/myFactory/salesInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/Detail/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/myFactory/salesInvoice/List/MaterialSalesInvoice/Detail/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'material-purchase',
            path: '/my-salesOrder/material-purchase',
            icon: 'gold',
            routes: [
              {
                path: '/my-salesOrder/material-purchase',
                redirect: '/my-salesOrder/material-purchase/list',
                exact: true,
              },
              {
                name: 'businessNavigator',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/materialPurchase/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'material-management',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/material-management',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path:
                      '/my-salesOrder/material-purchase/material-management',
                    redirect:
                      '/my-salesOrder/material-purchase/material-management/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'materialPurchaseDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/materialPurchaseDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Detail/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Detail/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialPurchaseSave',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/materialPurchaseSave',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__MaterialSalesInvoice__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partPurchaseSave',
                    path:
                      '/my-salesOrder/material-purchase/material-management/partPurchaseSave',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__PartSalesInvoice__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partPurchaseDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/partPurchaseDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Detail/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/Detail/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'materialPurchaseEdit',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/materialPurchaseEdit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/materialEdit/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/MaterialSalesInvoice/materialEdit/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partPurchaseEdit',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/material-purchase/material-management/partPurchaseEdit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__materialManagement__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/materialManagement/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/partEdit/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/materialManagement/List/PartSalesInvoice/partEdit/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'payment',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/payment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__payment__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/payment/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/payment/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/materialPurchase/payment/list')
                      .default,
                exact: true,
              },
              {
                name: 'purchase-freight',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/purchase-freight',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__purchaseFreight__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/purchaseFreight/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/purchaseFreight/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/materialPurchase/purchaseFreight/list')
                      .default,
                exact: true,
              },
              {
                name: 'purchase-invoice',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/material-purchase/purchase-invoice',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/material-purchase/purchase-invoice',
                    redirect:
                      '/my-salesOrder/material-purchase/purchase-invoice/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'materialDetail',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/materialDetail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'edit',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/edit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/MaterialPurchaseInvoice/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partSave',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/partSave',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/save'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/save')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partDetail',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/partDetail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partEdit',
                    path:
                      '/my-salesOrder/material-purchase/purchase-invoice/partEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__materialPurchase__PurchaseInvoice__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/materialPurchase/PurchaseInvoice/List/PartPurchaseInvoice/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'suProdOrder',
            icon: 'cluster',
            path: '/my-salesOrder/suProdOrder',
            routes: [
              {
                path: '/my-salesOrder/suProdOrder',
                redirect: '/my-salesOrder/suProdOrder/list',
                exact: true,
              },
              {
                name: 'businessNavigator',
                path: '/my-salesOrder/suProdOrder/businessNavigator',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'list',
                path: '/my-salesOrder/suProdOrder/list',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/List')
                      .default,
                exact: true,
              },
              {
                name: 'detail',
                path: '/my-salesOrder/suProdOrder/detail',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/Detail')
                      .default,
                showBack: true,
                hideInMenu: true,
                exact: true,
              },
              {
                name: 'save',
                path: '/my-salesOrder/suProdOrder/save',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/save'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/save')
                      .default,
                showBack: true,
                hideInMenu: true,
                exact: true,
              },
              {
                name: 'edit',
                path: '/my-salesOrder/suProdOrder/edit',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/edit'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/edit')
                      .default,
                showBack: true,
                hideInMenu: true,
                exact: true,
              },
              {
                name: 'work',
                path: '/my-salesOrder/suProdOrder/work',
                icon: 'FileSearchOutlined',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__suProdOrder__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/suProdOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/suProdOrder/Work'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/suProdOrder/Work')
                      .default,
                showBack: true,
                hideInMenu: true,
                exact: true,
              },
            ],
          },
          {
            name: 'stock-management',
            path: '/my-salesOrder/stock-management',
            icon: 'cluster',
            routes: [
              {
                name: 'businessNavigator',
                path: '/my-salesOrder/stock-management/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/stockManagement/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'productStockIn',
                path: '/my-salesOrder/stock-management/productStockIn',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/productStockIn',
                    redirect:
                      '/my-salesOrder/stock-management/productStockIn/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-management/productStockIn/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockIn/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockIn/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path: '/my-salesOrder/stock-management/productStockIn/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockIn__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockIn/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockIn/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockIn/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path:
                      '/my-salesOrder/stock-management/productStockIn/detail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockIn/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockIn/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'edit',
                    path: '/my-salesOrder/stock-management/productStockIn/edit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockIn/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockIn/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'productStockOut',
                path: '/my-salesOrder/stock-management/productStockOut',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/productStockOut',
                    redirect:
                      '/my-salesOrder/stock-management/productStockOut/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-management/productStockOut/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockOut/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockOut/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path:
                      '/my-salesOrder/stock-management/productStockOut/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockOut__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockOut/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockOut/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockOut/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path:
                      '/my-salesOrder/stock-management/productStockOut/detail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockOut/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockOut/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'proStockOutEdit',
                    path:
                      '/my-salesOrder/stock-management/productStockOut/proStockOutEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__productStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/productStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/productStockOut/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/productStockOut/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'materialStockIn',
                path: '/my-salesOrder/stock-management/materialStockIn',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialStockIn',
                    redirect:
                      '/my-salesOrder/stock-management/materialStockIn/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-management/materialStockIn/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockIn/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockIn/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path:
                      '/my-salesOrder/stock-management/materialStockIn/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockIn__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockIn/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockIn/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockIn/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'matStockInEdit',
                    path:
                      '/my-salesOrder/stock-management/materialStockIn/matStockInEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockIn/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockIn/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockIn/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'materialStockOut',
                path: '/my-salesOrder/stock-management/materialStockOut',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialStockOut',
                    redirect:
                      '/my-salesOrder/stock-management/materialStockOut/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-management/materialStockOut/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockOut/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockOut/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'save',
                    path:
                      '/my-salesOrder/stock-management/materialStockOut/save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockOut__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockOut/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockOut/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockOut/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'matStockOutEdit',
                    path:
                      '/my-salesOrder/stock-management/materialStockOut/matStockOutEdit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockOut/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockOut/edit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialStockOut/edit')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'material-stockInItem',
                path: '/my-salesOrder/stock-management/material-stockInItem',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockIn/Detail/detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/stockManagement/materialStockIn/Detail/detail')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'material-stockOutItem',
                path: '/my-salesOrder/stock-management/material-stockOutItem',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialStockOut/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialStockOut/Detail/detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/stockManagement/materialStockOut/Detail/detail')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'materialAllocation',
                path: '/my-salesOrder/stock-management/materialAllocation',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-management/materialAllocation',
                    redirect:
                      '/my-salesOrder/stock-management/materialAllocation/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'material-save',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/material-save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__Form__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/Form/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/Form/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'product-save',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/product-save',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__productForm__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/productForm/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/productForm/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/productForm/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/detail',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'product-edit',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/product-edit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/productEdit/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/productEdit/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'material-edit',
                    path:
                      '/my-salesOrder/stock-management/materialAllocation/material-edit',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockManagement__materialAllocation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockManagement/materialAllocation/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockManagement/materialAllocation/materialEdit/index'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockManagement/materialAllocation/materialEdit/index')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'stock-report',
            path: '/my-salesOrder/stock-report',
            icon: 'cluster',
            routes: [
              {
                name: 'businessNavigator',
                path: '/my-salesOrder/stock-report/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/businessNavigator/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/stockReport/businessNavigator/List')
                      .default,
                exact: true,
              },
              {
                name: 'productStock',
                path: '/my-salesOrder/stock-report/productStock',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/productStock',
                    redirect: '/my-salesOrder/stock-report/productStock/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/productStock/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__productStock__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/productStock/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/productStock/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/productStock/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'materialStock',
                path: '/my-salesOrder/stock-report/materialStock',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/materialStock',
                    redirect: '/my-salesOrder/stock-report/materialStock/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/materialStock/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__materialStock__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/materialStock/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/materialStock/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/materialStock/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'materialStockInOutItem',
                path: '/my-salesOrder/stock-report/materialStockInOutItem',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/materialStockInOutItem',
                    redirect:
                      '/my-salesOrder/stock-report/materialStockInOutItem/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-report/materialStockInOutItem/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__materialStockInOutItem__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/materialStockInOutItem/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/materialStockInOutItem/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/materialStockInOutItem/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'productStockInOutItem',
                path: '/my-salesOrder/stock-report/productStockInOutItem',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/productStockInOutItem',
                    redirect:
                      '/my-salesOrder/stock-report/productStockInOutItem/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path:
                      '/my-salesOrder/stock-report/productStockInOutItem/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__productStockInOutItem__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/productStockInOutItem/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/productStockInOutItem/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/productStockInOutItem/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'realTimeStock',
                path: '/my-salesOrder/stock-report/realTimeStock',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/stock-report/realTimeStock',
                    redirect: '/my-salesOrder/stock-report/realTimeStock/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my-salesOrder/stock-report/realTimeStock/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__realTimeStock__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/realTimeStock/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/realTimeStock/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/realTimeStock/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'redRushScrap',
                    path:
                      '/my-salesOrder/stock-report/realTimeStock/redRushScrap',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__stockReport__realTimeStock__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/stockReport/realTimeStock/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/stockReport/realTimeStock/RedRushScrap'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/stockReport/realTimeStock/RedRushScrap')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'finance',
            icon: 'DollarOutlined',
            path: '/my-salesOrder/finance',
            routes: [
              {
                path: '/my-salesOrder/finance',
                redirect: '/my-salesOrder/finance/list',
                exact: true,
              },
              {
                name: 'businessNavigator',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/finance/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'list',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/finance/List').default,
                exact: true,
              },
              {
                name: 'customer-period',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/customer-period',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/customer-period',
                    redirect: '/my-salesOrder/finance/customer-period/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/customer-period/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__CustomerPeriod__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/CustomerPeriod/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__CustomerPeriod__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/CustomerPeriod/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/CustomerPeriod/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/CustomerPeriod/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'payment-report',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/payment-report',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/payment-report',
                    redirect: '/my-salesOrder/finance/payment-report/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/payment-report/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__PaymentReport__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/PaymentReport/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__PaymentReport__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/PaymentReport/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/PaymentReport/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/PaymentReport/List')
                          .default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'invoice-information',
                icon: 'DollarOutlined',
                path: '/my-salesOrder/finance/invoice-information',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my-salesOrder/finance/invoice-information',
                    redirect: '/my-salesOrder/finance/invoice-information/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    icon: 'UserOutlined',
                    path: '/my-salesOrder/finance/invoice-information/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'materialSalesDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/finance/invoice-information/materialSalesDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialSalesInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialSalesInvoice/Detail')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'workProcessDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/finance/invoice-information/workProcessDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List/WorkProcessInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List/WorkProcessInvoice/Detail')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/finance/invoice-information/partSalesDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List/PartSalesInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List/PartSalesInvoice/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/finance/invoice-information/partPurchaseDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List/PartPurchaseInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List/PartPurchaseInvoice/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'partSalesDetail',
                    icon: 'UserOutlined',
                    path:
                      '/my-salesOrder/finance/invoice-information/materialPurchaseDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__supplier__mySalesOrder__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/finance/InvoiceInformation/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialPurchaseInvoice/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../supplier/mySalesOrder/finance/InvoiceInformation/List/MaterialPurchaseInvoice/Detail')
                          .default,
                    showBack: true,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            name: 'basic-archives',
            path: '/my-salesOrder/basic-archives',
            icon: 'cluster',
            routes: [
              {
                name: 'businessNavigator',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/businessNavigator',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/businessNavigator/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/businessNavigator/list')
                      .default,
                exact: true,
              },
              {
                name: 'part-file',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/part-file',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__partFile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/partFile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/partFile'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/partFile')
                      .default,
                exact: true,
              },
              {
                name: 'BatchImportPartsForm',
                path:
                  '/my-salesOrder/basic-archives/part-file/BatchImportPartsForm',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__partFile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/partFile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/partFile/BatchImportPartsForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/partFile/BatchImportPartsForm')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'customer-profile',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/customer-profile',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__customerProfile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/customerProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/customerProfile'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/customerProfile')
                      .default,
                exact: true,
              },
              {
                name: 'customer-profile',
                path: '/my-salesOrder/basic-archives/customer-profile/form',
                icon: 'car',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__customerProfile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/customerProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/customerProfile/form'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/customerProfile/form')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'team',
                path: '/my-salesOrder/basic-archives/customer-profile/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__customerProfile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/customerProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/customerProfile/CustomerDetail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/customerProfile/CustomerDetail')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'supplier-file',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/supplier-file',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__supplierFile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/supplierFile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/supplierFile'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/supplierFile')
                      .default,
                exact: true,
              },
              {
                name: 'warehouse archives',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/warehouse-archives',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__warehouseArchives__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/warehouseArchives/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/warehouseArchives'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/warehouseArchives')
                      .default,
                exact: true,
              },
              {
                name: 'scrapPriceArchives',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/scrapPrice-archives',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__scrapPriceArchives__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/scrapPriceArchives/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/scrapPriceArchives'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/scrapPriceArchives')
                      .default,
                exact: true,
              },
              {
                name: 'sendReceive-category',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/sendReceive-category',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__sendReceiveCategory__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/sendReceiveCategory/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/sendReceiveCategory'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/sendReceiveCategory')
                      .default,
                exact: true,
              },
              {
                name: 'operating-log',
                icon: 'switcher',
                path: '/my-salesOrder/basic-archives/operating-log',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__supplier__mySalesOrder__basicArchives__oparatingLog__model.ts' */ 'D:/yuncut_q/src/pages/supplier/mySalesOrder/basicArchives/oparatingLog/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__supplier__SupplierLayout" */ '../supplier/mySalesOrder/basicArchives/oparatingLog'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../supplier/mySalesOrder/basicArchives/oparatingLog')
                      .default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'setting',
        path: '/setting',
        icon: 'setting',
        routes: [
          {
            name: 'profile',
            path: '/setting/profile',
            icon: 'UserOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__profile__model.ts' */ 'D:/yuncut_q/src/pages/supplier/profile/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__profile" */ '../supplier/profile'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/profile').default,
            exact: true,
          },
          {
            name: 'subAccount',
            path: '/setting/supSubAccount',
            icon: 'TeamOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__supSubAccount__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/supSubAccount/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__supSubAccount" */ '../supplier/setting/supSubAccount'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/supSubAccount').default,
            exact: true,
          },
          {
            name: 'supplierRole',
            path: '/setting/roleManagement',
            icon: 'TeamOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__roleManagement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/roleManagement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__roleManagement" */ '../supplier/setting/roleManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/roleManagement').default,
            exact: true,
          },
          {
            name: 'roleMember',
            path: '/setting/roleManagement/roleMember',
            hideInMenu: true,
            showBack: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__roleManagement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/roleManagement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__roleManagement__Form__RoleMemberForm" */ '../supplier/setting/roleManagement/Form/RoleMemberForm'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/roleManagement/Form/RoleMemberForm')
                  .default,
            exact: true,
          },
          {
            name: 'unit-information',
            path: '/setting/unit-information',
            icon: 'audit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__unitInformation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/unitInformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__unitInformation" */ '../supplier/setting/unitInformation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/unitInformation').default,
            exact: true,
          },
          {
            name: 'factory-management',
            path: '/setting/factory-management',
            icon: 'audit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__factoryManagement__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/factoryManagement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__factoryManagement" */ '../supplier/setting/factoryManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/factoryManagement').default,
            exact: true,
          },
          {
            name: 'cutting-method',
            path: '/setting/cutting-method',
            icon: 'block',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__cuttingMethod__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/cuttingMethod/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__cuttingMethod" */ '../supplier/setting/cuttingMethod'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/cuttingMethod').default,
            exact: true,
          },
          {
            name: 'cutting-unit-price',
            path: '/setting/cutting-unit-price',
            icon: 'dollar',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__cuttingUnitPrice__model.ts' */ 'D:/yuncut_q/src/pages/supplier/cuttingUnitPrice/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__cuttingUnitPrice__CuttingUnitPrice" */ '../supplier/cuttingUnitPrice/CuttingUnitPrice'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/cuttingUnitPrice/CuttingUnitPrice')
                  .default,
            exact: true,
          },
          {
            name: 'device-information',
            path: '/setting/device-information',
            icon: 'reconciliation',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__deviceInformation__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/deviceInformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__deviceInformation" */ '../supplier/setting/deviceInformation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/deviceInformation').default,
            exact: true,
          },
          {
            name: 'payment-method',
            path: '/setting/payment-method',
            icon: 'money-collect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__paymentMethod__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/paymentMethod/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__paymentMethod" */ '../supplier/setting/paymentMethod'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/paymentMethod').default,
            exact: true,
          },
          {
            name: 'stock-manager',
            path: '/setting/stock-manager',
            icon: 'money-collect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__stockManager__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/stockManager/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__stockManager" */ '../supplier/setting/stockManager'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/stockManager').default,
            exact: true,
          },
          {
            name: 'invoice-manager',
            path: '/setting/invoice-manager',
            icon: 'money-collect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__Invoice__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/Invoice/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__Invoice" */ '../supplier/setting/Invoice'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/Invoice').default,
            exact: true,
          },
          {
            name: 'receiveAddress-manager',
            path: '/setting/receiveAddress-manager',
            icon: 'money-collect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__supplier__setting__ReceiveAddress__model.ts' */ 'D:/yuncut_q/src/pages/supplier/setting/ReceiveAddress/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__supplier__setting__ReceiveAddress" */ '../supplier/setting/ReceiveAddress'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../supplier/setting/ReceiveAddress').default,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
